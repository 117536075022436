import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel';

import logo from './img/lossi.jpg';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import img1 from './img/imgcontruccion1.jpg';
import img2 from './img/imgcontruccion2.jpg'
import img3 from './img/imgcontruccion3.jpg'
import img4 from './img/imgcontruccion4.jpg'
import img5 from './img/imgcontruccion5.jpg'
import img6 from './img/imgcontruccion6.jpg'


function App() {
  return (
    <div className="App">
      <div>
        <Navbar bg="light" data-bs-theme="light" >
          <Container>
            <Navbar.Brand href="#home"> <img src={ logo } className='logo'/> </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link href="mailto:lossidesarrolladora@gmail.com"><div className='nav-text'>lossidesarrolladora@gmail.com</div></Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </div>
      <br/>
      <Container>
        <Row>
          <Col md={ 7 }>
            <div className='main-banner'>
              <div className='title'> ¡Encontremos la opción ideal para usted!  </div>
              <br/>
              <div className='desc'> Nos dedicamos a la construcción, urbanización, restauración, proyectos, diseño, rehabilitación y mantenimiento de bienes inmuebles, pavimentación de caminos y puentes, compra-venta de todo tipo de materiales y maquinaria utilizado para la construcción, administración en general de bienes raíces, etc. </div>
              <br/>
              <div className='align-items-left'>
                <Button variant="dark" onClick={ () => window.location.href = 'mailto:lossidesarrolladora@gmail.com'}> Más información </Button>
              </div>
            </div>
          </Col>
          <Col md={ 5 } className='secondary-banner'>
            <div></div>
          </Col>
        </Row>
      </Container>
      <br/>

      <Container className='box'>
        <Row>
          <Col md={6}>
          <div className='box-vision'>
            <div>
              <h1>VISION</h1>
            </div>
            <div>
              <p>
                Ser la constructora líder elegida por nuestros proyectos de diseños
                innovadores, ingeniería, construcción y comercialización de
                inmuebles de alta calidad que desarrollamos, con personal
                altamente calificado, optimizando los costos pensando en ofrecer
                diferentes opciones a los clientes manteniendo altos estándares de
                calidad con servicio al cliente que garantice solidez y
                reconocimiento contribuyendo al desarrollo de nuestro país, y optar
                por lograr niveles de evolución altos.
              </p>
            </div>
          </div>
          </Col>
          <Col md={6}>
            <Carousel>
              <Carousel.Item interval={4000}>
                <img className="d-block w-100" src={img1} alt="First slide"/>
              </Carousel.Item>

              <Carousel.Item interval={4000}>
                <img className="d-block w-100" src={img2} alt="First slide"/>
              </Carousel.Item>

              <Carousel.Item interval={4000}>
                <img className="d-block w-100" src={img3} alt="First slide"/>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>

      <br/>

      <Container className='box'>
        <Row>
          <Col md={6}>
            <Carousel>
              <Carousel.Item interval={4000}>
              <img className="d-block w-100" src={img4} alt="First slide"/>
              </Carousel.Item>

              <Carousel.Item interval={4000}>
                <img className="d-block w-100" src={img5} alt="First slide"/>
              </Carousel.Item>

              <Carousel.Item interval={4000}>
                <img className="d-block w-100" src={img6} alt="First slide"/>
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col md={6}>
          <div className='box-mision'>
            <div>
              <h1>MISION</h1>
            </div>
            <div>
              <p>
                Realizar proyectos de construcción civil modernos que abarcan
                tanto el diseño, construcción e ingeniería, con excelencia de
                calidad, garantizando así un entorno agradable con el medio
                ambiente y satisfaciendo las necesidades de nuestros clientes
                como también el desarrollo de nuestro entorno social, económico.
                Generando fuentes de trabajo, fomentando un clima laboral que
                estimule el desarrollo personal y profesional de los trabajadores.
              </p>
            </div>
          </div>
          </Col>
        </Row>
      </Container>

      <div className='footer'>
        <Container>
          <Row>
            <Col md={ 6 }>
              <div className='footer-text align-items-left'>DESARROLLADORA LOSSI DEL SURESTE SA DE CV</div>
            </Col>
            <Col md={ 6 }>
              <div className='footer-text align-items-right'>All rights reserved ©</div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
